import './SecondBackground.css';

import React from 'react';
import house from './Assests/3D House Configurator.mp4';

export function SecondBackground() {
  return (
    <div className="se-background-container">
      <div className="se-video-container">
        <video autoPlay loop muted playsInline>
          <source src={house} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

