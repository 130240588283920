import './Form.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import Modal from 'react-modal';

export const CustomForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const validationSchema = Yup.object({
    firstname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field")
      .required('First name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phone: Yup.string()
      .matches(/^\d+$/, "Only numbers are allowed for this field")
      .required('Phone number is required'),
    message: Yup.string().required('Message is required'),
    agree: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  });

  const handleSubmit = (values, { resetForm }) => {
    // Submit to Web3Forms
    fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        access_key: '212dfa66-83f0-4ef8-85a9-10f40c58d45d',
        ...values,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setIsModalOpen(true);
          resetForm();
        }
      })
      .catch((error) => {
        console.error('Form submission error:', error);
      });
  };

  return (
    <div className='bgs'>
      <Formik
        initialValues={{ firstname: '', email: '', phone: '', message: '', agree: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="reg-for">
            <div className="form-group">
              <label className="form-label">First Name</label>
              <Field type="text" name="firstname" className="form-control" placeholder="First Name" />
              <ErrorMessage name="firstname" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label className="form-label">Email</label>
              <Field type="email" name="email" className="form-control" placeholder="Email" />
              <ErrorMessage name="email" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label className="form-label">Phone</label>
              <Field type="text" name="phone" className="form-control" placeholder="Phone" />
              <ErrorMessage name="phone" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label className="form-label">Message</label>
              <Field as="textarea" name="message" className="form-control" placeholder="Message..." rows="4" />
              <ErrorMessage name="message" component="div" className="error-message" />
            </div>

           
            <div className="form-group chk-grup">
            <Field type="checkbox" name="agree" className="form-check-input" id="agree" />
            <label className="form-check-label" htmlFor="agree">
              I agree that XM3DVIEW stores my submitted information so that they can respond to my request.
            </label>
            <ErrorMessage name="agree" component="div"  className="error-message" />
            </div>
            <div className="form-group">
              <button type="submit" className="Contact-btn w-100" disabled={isSubmitting}>
                Book Demo
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Submission Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2 className='modal-one'>Thank you for submitting</h2>
        <button  className="modal-close-btn"
        onClick={() => setIsModalOpen(false)}>Go Back</button>
      </Modal>
    </div>
  );
};
