import "./SecondLayer.css";

import { Link } from "react-router-dom";
import React from "react";
import homes from "./Assests/sample.png";

export function SecondLayer() {
  return (
    <div className="Layer-Second">
      <div className="second-layer-container">
        <div className="second-layer-content">
          <h1 className="second-layer-head" >
            Why you Book Demo ?
          </h1>
          <p className="second-home-para">
          Booking a demo lets you experience our textures, materials, and furniture in action through our 3D configurator. You'll receive personalized guidance, see how our products fit your design vision, and make informed decisions with expert help. It's the perfect way to ensure our offerings meet your needs and expectations.
          </p>
          <div>
            <Link to="/Demo">
              <button className="btn btn-primary">Book Demo</button>
            </Link>
          </div>
        </div>
        <div className="second-layer-image">
          <img src={homes} alt="Layer Image" />
        </div>
      </div>
    </div>
  );
}
