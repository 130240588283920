import "./Layer.css";

import React from "react";
import { fadeIn } from "../Test/Variants";
import layer from "./Assests/House.png";
import { motion } from "framer-motion";

export function Layer() {
  return (
    <div className="ls">
      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}
        className="grid-1"
      >
        <div className="left">
          <img src={layer} alt="home" className="imga"></img>
        </div>
        <div className="right">
          <p className="right-Para">
            All furniture and materials in our 3D house configurator can be
            fully customized to suit your preferences. The cost will vary based
            on the selection of materials and furniture, ensuring accurate
            pricing for your personalized design
          </p>
         

          <p className="right-Para">
            Immerse yourself in a world of endless possibilities as you tailor
            every detail of your dream home. Our configurator allows you to
            customize everything, from exterior textures to interior
            furnishings, ensuring a truly personalized experience
          </p>
        </div>
      </motion.div>
    </div>
  );
}
