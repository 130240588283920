import "./Main_Home.css";

import { Main_Header } from "./Main_Header";
import one from "./Assests/software-dev.png";

export function Main_Home() {
  return (
    <>
      <div>
        <img src={one} alt="cat" className="cat"></img>
      </div>
      <Main_Header></Main_Header>
    </>
  );
}
