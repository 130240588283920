import './Project_Header.css';

import React from "react";

export function Project_Header() {
  return (
    <div className="project-hero">
      <div className="project-text">
        <h3 className="project-text-header">Project.</h3>
        <p className="project-text-para">
          It gives you a unique insight into how we have transformed challenges
          into successful solutions by working closely with our clients.
        </p>
      </div>
      <div className="cat-explores">
        <span className="cat-ic">
          <i className="bi bi-arrow-down"></i>
        </span>
      </div>

    </div>
  );
}
