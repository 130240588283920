import './MainCards.css';

import React, { useRef, useState } from 'react';

import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function MainCards() {  
  const [selectedProjectUrl, setSelectedProjectUrl] = useState(null);
  const projectDisplayRef = useRef(null);

  const cardData = [
    {
      image: require('./Assests/3-Plan-Images-1BHK-Terrace-Flat.jpg'),
      title: '3D CONFIGURATOR',
      description: '3D MODEL',
      url: 'https://vara3dconfigurator.com/',
    },
    {
      image: require('./Assests/sofa.png'),
      title: '3D CONFIGURATOR',
      description: '3D MODEL',
      url: 'https://myfirstdiamond.fr/ARsofa/',
    },
    {
      image: require('./Assests/pombo.jpg'),
      title: 'HOME REPAIR APP',
      description: 'MOBILE APP',
      url: 'https://www.papabo.hk/en/',
    },
    {
      image: require('./Assests/LENS.jpg'),
      title: 'OPTICALCO',
      description: 'ECOMMERCE',
      url: 'https://theopticalco.com.au/',
    },
    {
      image: require('./Assests/homes.jpg'),
      title: 'BOOKING SYSTEM',
      description: 'BOOKINGSYSTEM',
      url: 'https://www.bannisters.com.au/',
    },
  ];

  const handleCardClick = (url) => {
    setSelectedProjectUrl(url);
    setTimeout(() => {
      projectDisplayRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100); 
  };

  const handleGoBack = () => {
    setSelectedProjectUrl(null);
  };

  return (
    <div className="card-back">
      {selectedProjectUrl && (
        <div className="project-display" ref={projectDisplayRef}>
        <p className="notification-message">
            Project opened above. Scroll up to view.
          </p>
          <iframe 
            src={selectedProjectUrl} 
            title="Project Display" 
            style={{ width: '80%', height: '700px', border: '5px solid gray', margin: '20px auto', display: 'block' }} 
          />
          <button onClick={handleGoBack} className="go-back-button">
            Go Back
          </button>
        </div>
      )}

      <div className="cards-container">
        {cardData.map((card, index) => (
          <Card 
            className="card" 
            key={index} 
            sx={{ maxWidth: 10000 }}
            onClick={() => handleCardClick(card.url)}
          >
            <CardActionArea className="one">
              <CardMedia 
                className="project-img"
                component="img"
                image={card.image}
                alt={card.title}
              />
              <div className="overlay">
                <CardContent className="card-content">
                  <Typography gutterBottom className='card-head'>
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {card.description}
                  </Typography>
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        ))}
      </div>
    </div>
  );
}
