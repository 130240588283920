import './Background.css';

import React from 'react';
import shirt from './Assests/shirt.mp4';

export function Background() {
  return (
    <div className="fs-background-container">
      <div className="fs-video-container">
        <video autoPlay loop muted playsInline>
          <source src={shirt} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

