import { Background } from './Background'
import { CarosuleSlider } from './CarosuleSlider'
import { ContactFooter } from '../ContactPages/ContactFotter'
import LastLayer from './LastLayer'
import { Layer } from './Layer'
import { Middles } from '../About/Middles'
import React from 'react'
import { SecondBackground } from './SecondBackground'
import { SecondLayer } from './SecondLayer'

function Home_Main() {
  return (
    <div>
      <CarosuleSlider></CarosuleSlider> 
      <Layer></Layer> 
      <Background></Background>
      <SecondLayer></SecondLayer>
      <SecondBackground></SecondBackground>
      <LastLayer></LastLayer>
      <ContactFooter></ContactFooter>
      
    </div>
  )
}

export default Home_Main
