import * as React from 'react';

import { useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from './Group 475.png';

const pages = ['Home', 'Explore', 'Services', 'Resources'];//contact Removed in future we add

export function Sample() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [Scroll, setScroll] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        backgroundColor: "transparent",
        boxShadow: "none",
        width: "100%",
        '@media (max-width: 100px)': {
          height: "50px", 
        },
      }}
    >
      <Container 
        sx={{ 
          backgroundColor: Scroll ? "rgba(0, 0, 0, 0.4)" : "none",
          backdropFilter: Scroll ? 'blur(100px)' : "none",
          boxShadow: "none",
          width: "80%",
          height: "8vh",
          marginTop: "20px",
          borderRadius: "50px",
          alignItems: "center",
          padding: '0',
          transition: 'background-color 0.1s ease-in-out',
          '@media (min-width: 100px) and (max-width:600px)': {
            width: "100%",
            height: "100%",
            padding: "5px 20px 5px 20px",
            marginTop: "10px",
           
          },
        }}
      >
        <Toolbar disableGutters>
          <Link to="/Home">
          <Box
            component="img"
            src={logo}
            alt="Logo"
            className="navbar-logo"
            sx={{
              height: 37,
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              '@media (min-width: 100px) and (max-width: 600px)': {
                height: "30px",
                mr: 1,
              },
            }}
          />
           </Link>

          <Box 
            className="navbar-pages" 
            sx={{ 
              flexGrow: 1, 
              display: { xs: 'none', md: 'flex' }, 
              justifyContent: 'center',
              '@media (max-width: 100px)': {
                display: 'none',  
              },
            }}
          >
            {pages.map((page) => (
              <Link key={page} to={`/${page}`} style={{ textDecoration: 'none' }}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ 
                    my: 2, 
                    // color: '#B8B8B9', 
                    color:'white',
                    display: 'block', 
                    mx: 2,
                    '&:hover': {
                      color: "#B8B8B9"
                    }
                  }}
                >
                  {page}
                </Button>
              </Link> 
            ))}
          </Box>

          <Box 
            className="navbar-actions" 
            sx={{ 
              display: { xs: 'flex', md: 'flex' }, 
              alignItems: 'center', 
              ml: 'auto',
              '@media (max-width: 100px)': {
                ml: 0,
                justifyContent: 'center',
              },
            }}
          >
            <Link to='/Demo' style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="secondary"
                className="navbar-demo-button"
                sx={{
                  display: { xs: 'none', md: 'flex' }, 
                  alignItems: 'center',
    
                  color: 'white',
                  width: 'fit-content',
                  padding: '10px 20px',
                  borderRadius: '50px',
                  marginBottom:'12px',
                  cursor: 'pointer',
                  border: '2px solid white',
                  transition: 'background-color 0.3s, transform 0.3s',
                  backgroundColor: 'transparent',
                  fontWeight: 800,
                  fontSize: '0.6rem',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    transform: 'scale(1.05)',
                  },
                  '@media (max-width: 100px)': {
                    padding: '5px 10px',
                    fontSize: '0.5rem',
                  },
                }}
              >
                Book Demo
              </Button>
            </Link>

            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className="navbar-menu-icon"
              sx={{ 
                display: { xs: 'flex', sm: 'flex', md: 'none', }, 
                '@media (max-width: 100px)': {
                  size: 'small',
                },
              }} 
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ 
              display: { xs: 'block', md: 'none' },
              '& .MuiPaper-root': {
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                backdropFilter: 'blur(50px)', 
                WebkitBackdropFilter: 'blur(10px)',
                textAlign: 'left',
                marginTop: Scroll ? "70px" : "70px",
                justifyContent: 'center',
                alignItems: 'left',
                borderRadius: '20px',
                width: '300px',
                padding: '1rem 1rem',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                '@media (max-width: 100px)': {
                  width: "100%",
                  marginTop: "10px",
                  padding: "0.5rem",
                },
              },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu} >
                <Link key={page} style={{ textDecoration: "none",width:"100%", }} to={`/${page}`}>
                  <Typography 
                    sx={{ 
                      textAlign: 'left', 
                       color: '#B8B8B9', 
    
                      padding: "0.5rem", 
                      '&:hover': {
                        color: "white"
                      },
                      '@media (max-width: 100px)': {
                        padding: "0.25rem",
                      },
                    }}
                  >
                    {page}
                  </Typography>
                </Link>
              </MenuItem>
            ))}

            
            <MenuItem onClick={handleCloseNavMenu} sx={{ display:"flex", alignItems:"center", justifyContent:"flex-start",}}>
              <Link to='/Demo' style={{ textDecoration: "none",display:"flex", alignItems:"center", justifyContent:"flex-start",width:"100%" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: '100%',                    padding: '10px',
                    cursor: 'pointer',
                    backgroundColor: "#248AFf",
                    fontWeight: 800,
                    borderRadius:"10px",
                    fontSize: '0.6rem',
                    color: '#B8B8B9',
                   
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '@media (min-width: 100px) and (max-width:600px)': {
                      width: '150%',
                      // padding: '5px',
                      fontSize: '0.6rem',
                    },
                  }}
                >
                  Book Demo
                </Button>
              </Link>
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
