import "./Middles.css";

import Home from "./Assests/aboutimage.jpg";
import React from "react";
import { fadeIn } from "../Test/Variants";
import { motion } from 'framer-motion';

export function Middles() {
  return (
    <div className="fs">
      <motion.div 

      variants={fadeIn("up", 0.2)}
    initial="hidden"
    whileInView={"show"}
    viewport={{ once: false, amount: 0.2 }}
      className="grid-1">
        <div className="left">
          <img src={Home} alt="home" className="imge"></img>
        </div>
        <div className="right">
          <h1 className="right-Heading">XM3DVIEW, anyone?<br></br> Anywho... Here we go!</h1>

          <p className="right-Para">
          Welcome to the future of home customization! Our 3D House Configurator
          offers a range of features and benefits that will revolutionize the way you
          design and personalize your dream home. Whether you're an individual
          homeowner or a real estate professional, our cutting-edge technology will
          leave a lasting impression on your investors and clients.
          </p>
        </div>
      </motion.div>
    </div>
  );
}
