import './Main_Header.css';

import React from "react";

export function Main_Header() {
  return (
    <div className="cat-header">
      <div className="cat-text">
        <h3 className="cat-text-header">This is Categories.</h3>
        <p className="cat-text-para">We help people make better decisions</p>
      </div>
      <div className="cat-explores">
        <span className="cat-ic">
          <i className="bi bi-arrow-down"></i>
        </span>
      </div>
    </div>
  );
}
