import { Contact } from './Contact'
import { ContactFooter } from './ContactFotter'
import { CustomForm } from './CustomForm'
import { Grid } from './Grid'
import React from 'react'

export function MainContact() {
  return (
    <div>
      <Contact></Contact>
      <CustomForm></CustomForm>
      <Grid></Grid>
      <ContactFooter></ContactFooter>
    </div>
  )
}

export default MainContact
