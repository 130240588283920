import './BackGround.css';
import { useEffect,useRef } from 'react';
import { Header } from './Header';
import React from 'react'
import { fadeIn } from "../Test/Variants";
import { motion } from 'framer-motion';
import videos from '../About/Assests/video_2.mp4';

export function BackGround() 
{
  const videoRef = useRef(null);
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.controls = false;
    }
  }, []);
    return(
        <>
          <div>
          <video className='background' ref={videoRef}  autoPlay loop muted playsInline>
            <source
              src={videos}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
     
        </div>
        <Header></Header>
        </>
    );
}

