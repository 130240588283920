import './CatCards.css';

import Ecomers from './Assests/shopping-online.jpg';
import {Link} from 'react-router-dom';
import React from 'react';
import house from './Assests/web.jpg';
import mobile from './Assests/mobi.png';
import sft from './Assests/software-developer.jpg';
import three from './Assests/Sample_car.jpg';
import watch from './Assests/UI-UX-Design.jpg';

const cardData = [
    { img: three, title: '3D Models' },
    { img: mobile, title: 'Mobile Apps' },
    { img: Ecomers, title: 'Ecomers Website' },
    { img: house, title: 'web Development' },
    { img: sft, title: 'Software Development' },
    { img: watch, title: 'UI &UX' }
];

const Card = ({ img, title }) => (
    <article className="card__article">
        <img src={img} alt={title} className="card__img" />
        <div className="card__data">
            <h2 className="card__title">{title}</h2>
           <Link className='card-link' to="/demo"> <button className="card__button">BookDemo</button></Link>
        </div>
    </article>
);

export function CatCards() {
    return (
        <div className="cat-bg">
            <div className="cat-container">
                <h3
                    className='cat-h1'
                    style={{
                        background: 'linear-gradient(to right, red, blue)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                    }}>
                    Choose your Category
                </h3>
                <p className='cat-p' style={{ marginBottom:"100px"}}>Explore our Categories and start customizing 3D models</p>

                <div className="card__container">
                    {cardData.slice(0, 6).map((card, index) => (
                        <Card key={index} img={card.img} title={card.title} />
                    ))}
                </div>
            </div>

           
        </div>
    );
}
