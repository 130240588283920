import { CatCards } from './CatCards'
import { Configuration } from './Configuration'
import { ContactFooter } from './../ContactPages/ContactFotter';
import { ContactPage } from '@mui/icons-material'
import { Main_Home } from './Main_Home'
import React from 'react'

function ResourceFinal() {
  return (
    <div>
      <Main_Home></Main_Home>
      <CatCards></CatCards>
     
      <ContactFooter/>
    </div>
  )
}

export default ResourceFinal
