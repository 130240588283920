import "./Hero.css";

import { Link } from "react-router-dom";
import React from "react";
import arrow from "../ContactPages/arrow_btn.png";
import { fadeIn } from "../Test/Variants";
import { motion } from "framer-motion";

export function Hero() {
  return (
    <div className="project-hero">
      <div className="project-text">
        <h3 className="project-text-header">BookDemo</h3>
        <p className="project-text-para">
          Create, review and send quotes in minutes. Ensure correct pricing
          using guided sales flows and discount rules. Avoid errors and reduce
          risks through built-in controls, approval flows and signatures in the
          quotation process.
        </p>
      </div>
      <div className="cat-explores">
        <span className="cat-ic">
          <i className="bi bi-arrow-down"></i>
        </span>
      </div>
    </div>
  );
}
