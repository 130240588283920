import "./Grid.css";

import { Form } from "./CustomForm";
import React from "react";
import { fadeIn } from "../Test/Variants";
import four from './Assessts/ContactImage4.jpg'
import im from './Assessts/img.jpg';
import { motion } from "framer-motion";
import one from './Assessts/ContactImage1.jpg'
import three from './Assessts/ContactPage3.jpg'
import two from './Assessts/ContactImage2.jpg'

export function Grid() {
    return (
        <div className="bg">
            <br></br>
            <motion.div
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.2 }}
                className="grid-container">
                <div className="left-part">
                    <h1 className="xm-hed">We are XM3DVIEW</h1>
                    <p className="xm-para">
                        We are XM3DVIEW – a leader in advanced visualization tools that
                        redefine digital commerce. With our expertise, we strive for
                        tailor-made solutions that improve sales and customer experience for
                        our partners.
                    </p>
                    <br></br>
                  
                </div>
                <div className="right-part">
                    <div className="sub">
                    <img src={im} alt='one'></img>
                    {
                        // <div className="column1">
                        //     <img src={one} alt="Image 1" />
                        //     <img src={two} alt="Image 2" />
                        //     <img src={three} alt="Image 3" />
                        // </div>
                        // <div className="column2">
                        //     <img src={four} alt="Image 4" />
                        //     <img src={five} alt="Image 5" />
                        // </div>
                    }
                    </div>
                </div>
            </motion.div>
        </div>
    );
}
