import "./Layer.css";

import{Link} from 'react-router-dom';
import React from "react";
import homes from "./Assests/All Devices Config-1.png";

export function Layer() {
  return (
    <div className="Home-Back">
      <div className="layer-container">
        <div className="layer-image">
          <img src={homes} alt="Layer Image" />
        </div>
        <div className="layer-content">
          <h2 className="layer-head">3D Products Visualization</h2>
          <p className="home-para">
        
          
          Explore our diverse collection of textures, materials, and furniture to match any style, from contemporary to traditional. Architects and interior designers can use our 3D configurator to create and share impressive designs with clients, ensuring that every detail meets their expectations and vision.
          
       
          </p>
          <div>
     
          <Link to="/Projects"><button className="btn btn-primary">Explore More</button></Link>
          </div>
        </div>
      </div>
    </div>
  );
}
