import './Preloader.css';

import React from 'react';
import logo from './Vector 214.png'; // Assuming you have your logo image in the same folder

export const Preloader = () => {
  return (
    <div className="preloader">
      <div className="logo-container">
        <img src={logo} alt="XM3D View Logo" className="logo" />
      </div>
      <p className="preloader-text">XM3DVIEW IS LOADING...</p>
    </div>
  );
};
