import "./ContactFooter.css";
import React from "react";


export function ContactFooter() {
  return (
    <div className="bgsm">
      
      <hr></hr>
      <div className="Footer-Copyright-Container">
      <div className="CopyRights">
        
      <p className="copy">
           XM3DVIEW Copyright © 2024 | Developed by Xstream Minds Pvt Ltd
        </p>
      </div>

      <div className="socialfooter-icons">
          <a target=" _blank" href="https://www.instagram.com/xm_3dview?igsh=NmhhYWg0eHZlbmpr">
            <span className="Socialfooter-Span">
              <i className="instagram bi bi-instagram"></i>
            </span>
          </a>
          <a target=" _blank"
            href="https://www.linkedin.com/company/xm3dview/">
            <span className="Socialfooter-Span">
              <i className="linkend bi bi-linkedin"></i>
            </span>
          </a>
          
      </div>
      </div>
    </div>
  );
}
