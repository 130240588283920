import './LastLayer.css';

import {Link} from 'react-router-dom';
import React from 'react';
import short from './Assests/sofas.mp4';

function LastLayer() {
  return (
    <div className='lg-background'>
    <div className="last-layer-container">
      <div className="last-layer-content">
        <h2 className='lg-head'>Increase Turnover and Reduce Costs in the Sales Process with XM3DVIEW</h2>
        <p className='lg-para'>
        XM3D View offers advanced 3D configuration software designed to help manufacturers streamline the sales and production of complex products. By integrating a powerful CPQ module, XM3D View enables businesses to provide a seamless, interactive customer experience, reducing errors and speeding up the sales and production process. Ideal for boosting 3D product sales, XM3D View aligns sales and production for optimal efficiency and customer satisfaction.
        </p>
       <Link to="/Explore"><button className="last-layer-button">Let's Talk</button></Link>
      </div>
      <div className="last-layer-video">
        <video  autoPlay loop muted playsInline>
          <source src={short} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    </div>
  );
}

export default LastLayer;
