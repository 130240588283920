import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import React, { useEffect, useState } from 'react';

import { Final } from './Components/About/Final'
import Home_Main from './Components/Home/Home_Main'
import MainContact from './Components/ContactPages/MainContact'
import { Preloader } from './Preloader';
import Project_Final from './Components/Project/Project_Final'
import ResourceFinal from './Components/Products/ResourceFinal'
import { Sample } from './NavBar/Sample'

export function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);
  return (
    <div>
    <BrowserRouter>
    
      {loading ? <Preloader /> : <Sample />}
    
    <Sample/>
    <Routes>
    <Route path='/' element={<Home_Main></Home_Main>}></Route>
    <Route path='/Home' element={<Home_Main></Home_Main>}></Route>
    <Route path='/Resources' element={<Final></Final>}></Route>
    <Route path='/Explore' element={<Project_Final></Project_Final>}></Route>
    <Route path='/Services' element={<ResourceFinal/>}></Route>
    {
      //<Route path='/Contact' element={<MainContact></MainContact>}></Route>
    }
    <Route path='/Demo' element={<MainContact></MainContact>}></Route>
    </Routes>
    </BrowserRouter>
    </div>
  )
}

